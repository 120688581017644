import { render, staticRenderFns } from "./Hot.vue?vue&type=template&id=24a8f08a&scoped=true&"
import script from "./Hot.vue?vue&type=script&lang=js&"
export * from "./Hot.vue?vue&type=script&lang=js&"
import style0 from "./Hot.vue?vue&type=style&index=0&id=24a8f08a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24a8f08a",
  null
  
)

export default component.exports