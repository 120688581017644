<template>
  <div class="hot">
    <HeaderHot :title="'资本热点'" :currentIndex="1"></HeaderHot>
    <Breadcrumb :navTitle="'资本热点'"></Breadcrumb>
    <div class="hot-content">
      <div class="content-left">
        <img :src="BaseUrl + itemData.tuiInfo.image" alt="" />
        <span :title="itemData.tuiInfo.title">{{
          itemData.tuiInfo.title
        }}</span>
      </div>
      <div class="content-right">
        <span class="right-title">资本热点排行榜</span>
        <div class="right-item">
          <span
            v-for="(item, index) in itemData.paiList"
            :key="item.id"
            :class="index < 3 ? 'active' : ''"
            :title="item.title"
            @click="goHotDetails(item.id)"
            >{{ index + 1 }} {{ item.title }}
          </span>
        </div>
      </div>
    </div>
    <div class="hot-list">
      <div
        class="item-list"
        v-for="item in itemList"
        :key="item.id"
        @click="goHotDetails(item.id)"
      >
        <span :title="item.title">{{ item.title }}</span>
        <span>{{ item.createtime }}</span>
        <img :src="BaseUrl + item.image" alt="" />
        <div>
          <span :title="item.brief">{{ item.brief }}</span>
          <span>[详细]</span>
        </div>
      </div>
    </div>
    <div class="pageDown">
      <el-pagination
        background
        prev-text="上一页"
        next-text="下一页"
        @prev-click="pagePrev"
        @next-click="pageNext"
        @current-change="pageChange"
        :page-size="9"
        layout=" prev, pager, next"
        :total="totalPage"
      >
      </el-pagination>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import HeaderHot from "../../../components/HeaderHot.vue";
import Breadcrumb from "../../../components/Breadcrumb.vue";
import Footer from "../../../components/Footer.vue";
export default {
  name: "Hot",
  data() {
    return {
      page: 1,
      pageSize: 9,
      totalPage: "",
      itemData: [],
      itemList: [],
    };
  },
  components: {
    HeaderHot,
    Breadcrumb,
    Footer,
  },
  created() {
    this.getData();
    this.getList();
  },
  methods: {
    // 获取热点页面数据
    getData() {
      this.$http({
        url: "/caijing/index",
      }).then((res) => {
        console.log(res, "res");
        this.itemData = res.data;
      });
    },
    // 获取热点列表
    getList(page) {
      this.$http({
        url: "/caijing/list",
        params: {
          page: page ? page : this.page,
          pagesize: this.pageSize,
        },
      }).then((res) => {
        console.log(res, "res");
        this.itemList = res.data.list;
        this.totalPage = res.data.total;
      });
    },
    // 上一页
    pagePrev() {
      this.page--;
      this.getList(this.page);
    },
    // 下一页
    pageNext() {
      console.log("下一页");
      this.page++;
      this.getList(this.page);
    },
    // 页面改变
    pageChange(e) {
      console.log(e);
      this.page = e;
      this.getList(this.page);
    },
    //跳转热点详情
    goHotDetails(id) {
      console.log(id);
      this.$router.push(`/hotdetails/${id}`);
    },
  },
};
</script>

<style lang="less" scoped>
body {
  width: 1900px;
  overflow: hidden;
}
.active {
  color: #2050ac;
}
.hot {
  .hot-content {
    display: flex;
    width: 1200px;
    margin: 0 auto 60px;
    overflow: hidden;
    .content-left {
      position: relative;
      width: 730px;
      height: 438px;
      margin-right: 61px;
      > img:nth-child(1) {
        position: absolute;
        width: 730px;
        height: 438px;
      }
      > span:nth-child(2) {
        width: 730px;
        height: 40px;
        line-height: 40px;
        position: absolute;
        bottom: 0;
        text-indent: 1.2em;
        background-color: rgba(0, 0, 0, 0.32);
        color: #fff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .content-right {
      width: 409px;
      display: flex;
      flex-direction: column;
      .right-title {
        width: 168px;
        height: 50px;
        line-height: 30px;
        font-size: 22px;
        margin-bottom: 14px;
        border-bottom: 2px solid #12407d;
        color: #333333;
      }
      .right-item {
        display: flex;
        flex-direction: column;
        font-size: 16px;
        color: #333333;
        span {
          margin-top: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
        }
        span:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .hot-list {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    .item-list {
      width: 380px;
      display: flex;
      margin-right: 20px;
      flex-direction: column;

      > span:nth-child(1) {
        height: 62px;
        font-size: 22px;
        color: #333333;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      > span:nth-child(1):hover {
        text-decoration: underline;
      }
      > span:nth-child(2) {
        font-size: 20px;
        margin: 31px 0 23px 0;
        color: #666666;
      }
      > img:nth-child(3) {
        width: 380px;
        height: 300px;
      }
      > div:nth-child(4) {
        width: 378px;
        margin-bottom: 60px;
        > span:nth-child(1) {
          font-size: 14px;
          color: #666666;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        > span:nth-child(2) {
          font-size: 14px;
          color: #2094e1;
          cursor: pointer;
        }
      }
    }
  }
  .pageDown {
    width: 1200px;
    margin: 50px auto 0;
    text-align: center;
  }
}
</style>
